import videojs from 'video.js';
import './assets/sass/player.scss';
import 'videojs-contrib-hls';
import 'videojs-contrib-quality-levels';
import videojsErrors from 'videojs-errors';
import videojsqualityselector from 'videojs-hls-quality-selector';
import socialShareOverlay from 'videojs-social-share-overlay';
import vttThumbnails from 'videojs-vtt-thumbnails';
import Api from './Api';
import PlayerDefaults from './PlayerDefaults';
import Emitter from 'component-emitter';
import EndCardPlugin from './Plugins/EndCard';
import DaiAds from './Plugins/DaiAds';
import flashSwf from 'file-loader?name=swf/[name].[ext]!./assets/swf/video-js.swf';
import Rewind from './Plugins/Rewind';
import emptyMp4WithSound from 'file-loader?name=media/_.[ext]!./assets/media/mp4-with-audio.mp4';

const TYPE_HLS = 'application/x-mpegURL';

/**
 * Video Player class.  Accepts an element reference,
 * and a config object.
 * @class
 * @extends Api
 * @example <div id="videoEl"></div>
 * <script>
 * // Instantiate a simple video player.
 * var videoEl = document.getElementById('videoEl');
 * var myPlayer = new Player(videoEl, {
 *   playlist: [{
 *    poster: 'path/to/poster.jpeg',
 *    sources: {
 *      src: 'path/to/my/video.mp4',
 *      type: 'application/x-mpegURL'
 *    }
 *  }]
 * });
 * </script>
 */

/**
 * The configuration object for the player class.  Either the
 *  file property or the playlist property are required, but not both.
 *
 * @typedef {Object}  Player#PlayerConfig
 *
 * @property {boolean}   debug=false - Whether or not to log verbose output to the console
 * @property {boolean}   autoplay=true - Whether or not to try to autoplay the video(s)
 * @property {Array}     techOrder=html5 - The type of ad service to use. Currently only `dai` is supported
 * @property {string}    adType=null - The type of ad service to use. Currently only `dai` is supported
 * @property {boolean}   pauseInBackground=true - Whether or not to pause video when in the background
 * @property {string}    path - Absolute URI to player assets for lazy loading.
 * @property {string}    flashSrc - The path to the .swf used for the flash player.
 * @property {number}    playlistIndex=0 - The index at which to begin playlist
 * @property {boolean}   loopPlaylist=false - Whether or not to loop the playlist on completion.
 * @property {boolean}   playsInline=true - Whether or not to play the video inline on supported mobile devices.
 * @property {boolean}   ipv=false - Whether or not to setup the player controls in an IPV arrangement
 * @property {boolean}   rewindButton=false - Whether or not to display the rewind 10s button.
 * @property {number}    rewindTime=10 - Time to rewind player on rewind button click (keep in mind the icon should be styled appropriately.
 *
 * @property {Object}    vr - VR configuration
 * @property {string}    vr.projection - 360|sphere
 * @property {boolean}   vr.debug - Whether or not to log VR output
 *
 * @property {Object}    ima - The Google IMA/DAI configuration
 * @property {number}    ima.cmsId - The Google DAI cmsId associated with a feed to use
 * @property {boolean}   ima.debug - Whether or not to log ad output
 *
 * @property {Object}    conviva - The conviva configuration
 * @property {string}    conviva.key - The conviva provided API key
 * @property {string}    conviva.touchstoneUrl - The conviva provided touchstone URL, used for testing only
 *
 * @property {Player#PlaylistItem}    file - A single video element configuration. @link PlayerConfig.playlist
 * @property {Player#PlaylistItem[]}  playlist - The playlist
 *
 */

/**
 * Playlist item definition.
 *
 * @typedef {Object}  Player#PlaylistItem
 *
 * @property {string}   title - The title of the playlist item
 * @property {string}   description - The description of the playlist item
 * @property {string}   poster - URI to an image which can be used as the default image for that video
 * @property {number}   contentId - The VMS ID of the asset, used for ads retrieval as well analytics/reporting
 * @property {url}      url - Sharable permalink to the video asset or the page containing the video player
 * @property {Object[]} sources - Sources for the asset
 * @property {string}   sources.src - URI to an asset containing playback data for the video
 * @property {string}   sources.type - Mime type of media being played back, for example 'video/mp4'
 * @property {Object[]} tracks - Tracks for the video asset, VTT Captions and VTT Thumbnails among types supported
 * @property {string}   tracks.src - URI to a time encoded asset containing data for the track
 * @property {string}   tracks.kind - Type of track, e.g. `thumbnails` or `captions`
 * @property {string}   tracks.language - If captions, a language may be specified
 * @property {Object}   share - Share configuration for share overlay plugin.
 * @property {Array}    share.platforms - An array of the platforms to display share actions for (twitter, facebook, link)
 * @property {string}   share.emailBody - Copy to use in email body of share plugin
 * @property {string}   share.via - Primarily used for twitter, the @via username to share from
 * @property {Object}   endCard - The endcard configuration
 * @property {Object}   endCard.duration=15 - the duration in seconds to display the endcard.
 * @property {Object}   endCard.content - The endcard markup, in HTML, to display.
 */

class Player extends Api {
  /**
   * Instantiate a player instance
   *
   * @param {string|HTMLElement} elReference - The element
   *  selector, or element reference.
   * @param {Player#PlayerConfig} config - The configuration for the player instance.
   */
  constructor(elReference, config) {
    super();

    // Emit-iate this pobject.
    Emitter(this);

    // Set the wrapper element reference.
    if (typeof elReference === 'string') {
      this.el = document.querySelector(elReference);
    } else {
      this.el = elReference;
    }

    // Cannot have an empty element reference.
    if (!this.el) throw 'Player is not a valid element.';

    // Build config.
    this.config = Object.assign({}, new PlayerDefaults(), config);

    // Set public path if it exists.
    if (this.config.path) {
      Player.setPublicPath(this.config.path);
    }

    // Logging.
    videojs.log.level(this.config.debug ? 'debug' : 'off');

    // Conviva
    if (this.config.conviva) {
      const convivaPlugin = this.loadConviva();
      convivaPlugin.then(loadedModule => {
        this.conviva = new loadedModule.default(this, this.config.conviva);
        this.initializePlayer();
      });
    } else {
      // Initialize the player
      this.initializePlayer();
    }

    return this;
  }

  async loadConviva() {
    return await import(/* webpackChunkName: 'conviva' */ './Plugins/Conviva');
  }

  /**
   * Initialize the internal player.
   * @access private
   */
  initializePlayer() {
    this.setupPlaylist();
    this.preSetupInternalPlayer();
    this.initializeEndCard();
  }

  /**
   * Initialize end card plugin.
   */
  initializeEndCard() {
    this.endcard = new EndCardPlugin(this);
  }

  /**
   * Get the first video to play.
   */
  getInitialVideo() {
    return this.config.playlist[this.playlistIndex || 0];
  }

  /**
   * Setup internal playlist.
   * @access private
   */
  setupPlaylist() {
    this.playlistIndex = this.config.playlistIndex || 0;

    if (this.config.file && this.config.playlist)
      throw 'Cannot specify both file and playlist.';

    if (!this.config.file && !this.config.playlist)
      throw 'Either a file or playlist must be specified.';

    if (this.config.file) {
      this.config.playlist = [this.config.file];
    }
  }

  /**
   * Get the current numerical index specifiying which
   * video in the playlist is currently being played.
   *
   * @returns {number|*}
   */
  getCurrentIndex() {
    return this.playlistIndex || 0;
  }

  /**
   * This is used only during initial player setup.
   *
   * @param preparedPlaylistItem
   * @returns {*}
   */
  getInitialPlaylistItem(preparedPlaylistItem) {
    delete preparedPlaylistItem.tracks;
    return preparedPlaylistItem;
  }

  /**
   * Returns a groomed (vs. raw) instance of the current
   * playlist item.
   *
   * @param {object} playlistItem
   * @returns {object}
   */
  getPreparedPlaylistItem(playlistItem) {
    if (!playlistItem) {
      this.log('Cannot prepare empty playlist item.');
      return null;
    }

    // Merge the defaults.
    const item = Object.assign({}, this.config, playlistItem);

    // Handle thumbs.
    let thumbIndex = null;
    const thumbs =
      item.tracks &&
      item.tracks.filter((track, index) => {
        thumbIndex = index;
        return track.kind === 'thumbnails';
      })[0];

    // Assign thumbs and purge them from the tracks.
    if (thumbs) {
      item.vttThumbnails = thumbs;
      item.tracks.splice(thumbIndex, 1);
    }

    return item;
  }

  /**
   * Loads flash tech.
   *
   * @private
   * @returns {Promise<*>|*}
   */
  getFlashTech() {
    return import(/* webpackChunkName: "flash-tech"*/ 'videojs-flash');
  }

  /**
   * Loads flash HLS.
   *
   * @private
   * @returns {Promise<*>|*}
   */
  getFlashSourceHandler() {
    return import(/* webpackChunkName: "flash-handler"*/ 'videojs-flashls-source-handler');
  }

  /**
   * Loads IPV Plugin.
   *
   * @private
   * @returns {Promise<*>|*}
   */
  getIpvPlugin() {
    return import(/* webpackChunkName: "ipv-plugin"*/ './Plugins/Ipv');
  }

  /**
   * Initializes IPV plugin.
   *
   * @private
   * @returns {Promise<void>}
   */
  async setupIpv() {
    const Ipv = await this.getIpvPlugin();
    const IpvPlugin = Ipv.default;
    this.ipv = new IpvPlugin(this);
  }

  /**
   * Loads flash plugins.
   *
   * @private
   * @returns {Promise<void>}
   */
  async getFlashDeps() {
    videojs.options.flash.swf = this.config.flashSrc || flashSwf;

    let flashTech = await this.getFlashTech();
    let flashHandler = await this.getFlashSourceHandler();

    videojs.registerTech('Flash', flashTech.default);
    videojs.getTech('Flash').registerSourceHandler(flashHandler.default, 0);

    return flashTech;
  }

  /**
   * Loads VR plugin.
   *
   * @private
   * @returns {Promise<*>|*}
   */
  getVr() {
    return import(/* webpackChunkName: "vr-video"*/ './Plugins/VrVideo');
  }

  /**
   * Initialize player with VR.
   *
   * @private
   * @returns {Promise<VideoVrImport.default>}
   */
  async initVr() {
    const VideoVrImport = await this.getVr();
    const VideoVr = VideoVrImport.default;
    return new VideoVr(this);
  }

  /**
   * "Middleware" for setupInternalPlayer.
   *
   * @private
   */
  preSetupInternalPlayer() {
    if (
      videojs.browser.IE_VERSION >= 11 &&
      this.config.techOrder.indexOf('Flash') < 0
    ) {
      this.log('IE 11 Detected.  Adding [Flash] Tech.');
      this.config.techOrder.push('Flash');
    }

    if (this.config.techOrder.indexOf('Flash') >= 0) {
      this.getFlashDeps().then(() => this.setupInternalPlayer());
    } else {
      this.setupInternalPlayer();
    }
  }

  /**
   * Setup the internal player used by this wrapper.
   *
   * @access private
   * @param {object} playlistItem - the unprepared playlist item.
   */
  /* istanbul ignore next */
  setupInternalPlayer() {
    const startVideo = this.getInitialVideo();
    const playlistItem = this.getPreparedPlaylistItem(startVideo);
    const preparedPlaylistItem = this.getInitialPlaylistItem(playlistItem);
    this.currentVideoEl = this.createInternalPlayerElement(
      preparedPlaylistItem
    );

    // Disable right click.
    this.disableRightClick();

    this.currentPlayer = null;
    delete this.currentPlayer;

    // Bind events on this instance (not the internal player).
    this.bindOtherEvents(this);

    // Autoplay.
    if (this.config.autoplay === false) {
      preparedPlaylistItem.autoplay = false;
    }

    // IPV - Volume
    if (this.config.ipv) {
      preparedPlaylistItem.controlBar = {
        volumePanel: { inline: false }
      };
    }

    // Set language
    if (window._player_lang_) {
      preparedPlaylistItem.language = window._player_lang_;
    }

    // Non native-hls
    if (this.useNonNativeHls()) {
      preparedPlaylistItem.html5 = {
        hls: {
          overrideNative: true
        }
      };
    }

    // Initialize the internal player.
    this.currentPlayer = videojs(
      this.currentVideoEl,
      preparedPlaylistItem,
      () => {
        // Ready callback.
        this.internalPlayerReady(this.currentPlayer);
        this.emit('beforePlay');
      }
    );

    this.currentPlayer.errors = videojsErrors;
    this.currentPlayer.errors();
    this.currentPlayer.errors.timeout(this.config.timeout || 120000);

    // VR.
    if (this.config.vr) {
      this.initVr();
    }

    // Autoplay.
    if (this.config.autoplay !== false) {
      this.currentPlayer.addClass('vjs-autoplaying');
    }

    // Fix for iOS cellular
    this.cellularSafariRenderBugFix();

    // IPV
    if (this.config.ipv) {
      this.setupIpv();
    }

    // Register middleware
    this.currentPlayer.getWrapper = () => this;
    if (!global.middlewareRegistered) {
      this.registerMiddleware();
      global.middlewareRegistered = true;
    }

    // If DAI, change source.
    if (
      this.adType() === 'dai' &&
      preparedPlaylistItem.contentId &&
      !preparedPlaylistItem.ads_disabled
    ) {
      DaiAds.setStitchedStream(
        this,
        preparedPlaylistItem,
        this.config.ima.cmsId,
        (Array.isArray(preparedPlaylistItem.sources)
          ? preparedPlaylistItem.sources[0].src
          : preparedPlaylistItem.sources.src) || null
      ).catch(() => {
        this.log('[Error]', 'Could not get a DAI stream');
      });
    }

    // Add meta component.
    this.createMetaOverlay();
    this.setMeta({
      title: preparedPlaylistItem.title,
      description: preparedPlaylistItem.description
    });

    // Add quality selector button
    this.currentPlayer.hlsQualitySelector = videojsqualityselector;
    this.currentPlayer.hlsQualitySelector();

    // Bind videojs events.
    this.bindInternalEvents();

    // Append thumbs to timeline.
    this.currentPlayer.vttThumbnails = vttThumbnails;
    if (preparedPlaylistItem.vttThumbnails) {
      this.currentPlayer.vttThumbnails({
        src: preparedPlaylistItem.vttThumbnails.src
      });
    }

    // Set share params
    this.currentPlayer.socialShareOverlay = socialShareOverlay;
    this.currentPlayer.socialShareOverlay(this.getShareConfig());

    // Bind visibility listener.
    if (this.config.pauseInBackground) {
      const visProps = this.getVisibilityChangeVendorSpecific();
      document.addEventListener(
        visProps.visibilityChange,
        e => this.handleVisibilityChange(e),
        false
      );
    }

    if (this.config.rewindButton) {
      new Rewind(this, this.rewindTime || 10);
    }
  }

  /**
   * Disable context menu on certain platforms.
   *
   * @private
   */
  disableRightClick() {
    if (!this.currentVideoEl) {
      return;
    }
    this.currentVideoEl.addEventListener('contextmenu', event =>
      event.preventDefault()
    );
  }

  /**
   * Purposely-ugly named function which fixes an odd
   *  repaint/render issue on mobile safari.  We should
   *  try to get rid of this eventually.
   */
  /* istanbul ignore next */
  cellularSafariRenderBugFix() {
    const browsers = this.getBrowsers();
    if (browsers.IS_IPHONE && browsers.IS_ANY_SAFARI) {
      this.once('firstFrame', () => {
        this.currentPlayer.controlBar.hide();
        setTimeout(() => this.currentPlayer.controlBar.show(), 1000);
      });
    }
  }

  /**
   * Returns android version from UA.  We cant use videojs
   *  for this because the player has not yet been instantiated
   *  where we need it.
   *
   * @returns {*}
   */
  getAndroidVersion() {
    const ua = navigator.userAgent;
    if (ua.indexOf('Android') >= 0) {
      return parseFloat(ua.slice(ua.indexOf('Android') + 8));
    }
    return false;
  }

  /**
   * Run all conditions to determine if we need to force
   *  non-native HLS.
   *
   * @returns {boolean}
   */
  useNonNativeHls() {
    const androidVersion = this.getAndroidVersion();
    // We need to force Non-native HLS for all android.
    if (androidVersion !== false) {
      return true;
    }
  }

  /**
   * Sets current player source.
   *
   * @private
   * @param currentItem
   */
  setSource(currentItem) {
    this.currentPlayer.src(currentItem.sources);
  }

  getDai() {
    return DaiAds;
  }

  /**
   * Registers a middleware callback.
   *
   * @private
   */
  /* istanbul ignore next */
  registerMiddleware() {
    // register a star-middleware because HLS has two mimetypes
    videojs.use('*', vjsPlayer => {
      let player = vjsPlayer.getWrapper();

      return {
        duration(durationFromTech) {
          const currentItem = player.getCurrentPlaylistItem();

          // Live videos have a different duration.
          if (currentItem.isLive) {
            return player.currentPlayer.seekable().end(0);
          }

          // Ads should show ad duration.
          if (player.isLinearAdPlaying()) {
            return currentItem.adLength || durationFromTech;
          }
          return durationFromTech - (currentItem.adLength || 0);
        },

        play() {
          // We have to check current time here because when a video
          //  ends, vjs internal playhead skips our middlewares and
          //  sets the the current time to 0, even though in cases with
          //  a stitched ad it should be set to 15s for example.
          try {
            if (player.getCurrentTime() < 0) {
              player.setCurrentTime(0);
            }
          } catch (err) {
            player.log('Middleware [PLAY] Error');
          }
        },

        currentTime(ct) {
          const currentItem = player.getCurrentPlaylistItem();
          if (player.isLinearAdPlaying()) {
            return ct;
          }
          return ct - (currentItem.adLength || 0);
        },

        setCurrentTime(ct) {
          const currentItem = player.getCurrentPlaylistItem();
          if (player.isLinearAdPlaying()) {
            return ct;
          }
          return ct + (currentItem.adLength || 0);
        },

        setSource(srcObj, next) {
          return next(null, srcObj);
        }
      };
    });
  }

  /**
   * Gets current bitrate if available for that platform.
   * @returns {*}
   */
  getQualityLevel() {
    if (
      !this.currentPlayer ||
      typeof this.currentPlayer.qualityLevels !== 'function'
    ) {
      return null;
    }
    let qualityLevels = this.currentPlayer.qualityLevels();
    let selectedLevel = qualityLevels.selectedIndex;
    if (selectedLevel < 0) {
      return null;
    }
    return qualityLevels[selectedLevel];
  }

  /**
   * Create the element to bind the player to.
   *
   * @private
   * @param playlistItem
   * @returns {HTMLVideoElement}
   */
  createInternalPlayerElement(playlistItem) {
    const currentDate = new Date();

    let playerEl = document.createElement('video');
    playerEl.id = 'player_' + currentDate.getTime();
    playerEl.className = 'video-js vjs-default-skin vjs-16-9 vjs-player-theme';

    // Set player id on class.
    this.playerId = playerEl.id;

    // Add controls attribute.
    playerEl.setAttribute('controls', 'controls');

    // Add preload attribute.
    playerEl.setAttribute('preload', 'auto');

    // Add autoplay attribute.
    if (this.config.autoplay !== false) {
      playerEl.setAttribute('autoplay', 'autoplay');
    } else {
      // Even if autoplay is initially false, set it to true
      // to continue playlists on iOS devices.
      this.on('firstFrame', () => {
        this.currentPlayer.autoplay(true);
      });
    }

    // Add autoplay attribute.
    const browsers = this.getBrowsers();
    if (this.config.playsinline !== false && browsers.TOUCH_ENABLED) {
      playerEl.setAttribute('playsinline', 'playslinline');
    }

    let contentSrc;
    if (playlistItem.sources) {
      playlistItem.sources.forEach(item => {
        contentSrc = document.createElement('source');
        contentSrc.setAttribute('src', item.src);
        contentSrc.setAttribute('type', item.type);
        playerEl.appendChild(contentSrc);
      });
    }

    this.el.appendChild(playerEl);

    // Add autoplay attribute.
    if (this.config.autoplay !== false) {
      const playPromise = playerEl.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          // Auto-play was prevented
          // Show paused UI.
          this.log('[Autoplay]', error);
        });
      }
    }

    return playerEl;
  }

  /**
   * Detemrines whether a linear ad is currently playing.  Use
   *  caution with autoplay as ad started event can fire even
   *  if video has not begun.
   *
   * @returns {boolean}
   */
  isLinearAdPlaying() {
    return this.linearAdPlaying;
  }

  /**
   * Attempts to play player.  Used to catch autoplay
   *  errors.  If autoplay fails, add class to player
   *  so that user can initiate playback on their own.
   */
  attemptToPlay() {
    if (this.config.autoplay === false || !this.currentPlayer.paused()) {
      return Promise.resolve();
    }
    try {
      return this.play().catch(e => {
        this.log('[Autoplay] Failure: ', e);
        this.currentPlayer.addClass('vjs-autoplay-failed');
      });
    } catch (e) {
      this.log('[Autoplay] Caught: ', e);
    }
  }

  /**
   * Binds internal player events.
   *
   * @private
   */
  bindInternalEvents() {
    const player = this.currentPlayer;

    player.on('play', event => {
      this.logEvent('play');
      this.internalPlayerPlay(event);
    });

    player.on('pause', () => {
      let event = this.linearAdPlaying ? 'adPause' : 'pause';
      this.logEvent(event);
      this.emit(event);
    });

    player.on('playing', event => {
      this.logEvent('playing');
      this.internalPlayerPlaying(event);
    });

    player.on('loadeddata', () => {
      this.logEvent('loadeddata');
      this.attemptToPlay();
    });

    player.on('loadedmetadata', () => {
      this.logEvent('loadedmetadata');
    });

    /**
     * @emits Player#fullscreenchange
     */
    player.on('fullscreenchange', () => {
      this.logEvent('fullscreenchange');

      /**
       * @event Player#fullscreenchange
       */
      this.emit('fullscreenchange');
    });

    /**
     * @emits Player#error
     */
    player.on('error', event => {
      this.logEvent('error');

      /**
       * @event Player#error
       */
      this.emit('error', event);
    });

    player.on('ended', event => {
      this.logEvent('ended');
      this.lastVideoEnded = this.getCurrentIndex();
      this.internalPlayerEnded(event);
    });

    player.on('timeupdate', () => {
      if (this.linearAdPlaying) {
        return;
      }
      this.emitQuartiles(player);
      this.stallCheck();
    });

    /**
     * @emits Player#shareOverlayOpen
     */
    player.on('share-overlay-open', event => {
      /**
       * @event Player#shareOverlayOpen
       */
      this.emit('shareOverlayOpen', event);
    });

    /**
     * @emits Player#shareOverlayClosed
     */
    player.on('share-overlay-closed', event => {
      /**
       * @event Player#shareOverlayClosed
       */
      this.emit('shareOverlayClosed', event);
    });

    /**
     * @emits Player#shareInitiated
     */
    player.on('share-initiated', event => {
      /**
       * @event Player#shareInitiated
       */
      this.emit('shareInitiated', event);
    });

    /**
     * @emits Player#waiting
     */
    player.on('waiting', () => {
      this.logEvent('waiting');
    });
  }

  /**
   * A timer mechanism to determine if video was stalled.
   *
   * @private
   * @emits Player#playbackstalled
   */
  /* istanbul ignore next */
  stallCheck() {
    clearInterval(this.timeupdateInterval);

    this.timeupdateIntervalStarted = 0;
    this.timeupdateInterval = setInterval(() => {
      if (this.isLinearAdPlaying() || this.paused()) {
        return;
      }

      ++this.timeupdateIntervalStarted;

      if (this.timeupdateIntervalStarted >= (this.stallTimeout || 15)) {
        /**
         * @event Player#playbackstalled
         */
        this.emit('playbackstalled');
        clearInterval(this.timeupdateInterval);
      }
    }, 1000);
  }

  /**
   * Emit quartile events during video play.
   *
   * @param player - The VideoJS instance.
   * @private
   *
   * @emits Player#firstqaurtile - Video progress has surpassed
   *  25% of the video overall duration.
   * @emits Player#midpoint - Video progress has surpassed
   *  50% of the video overall duration.
   * @emits Player#thirdquartile - Video progress has surpassed
   *  75% of the video overall duration.
   */
  emitQuartiles(player) {
    const timeEvents = [
      /**
       * @event Player#firstqaurtile
       */
      {
        prop: 'firstQuartileReported',
        percentage: 0.25,
        event: 'firstquartile'
      },
      /**
       * @event Player#midpoint
       */ {
        prop: 'midpointReported',
        percentage: 0.5,
        event: 'midpoint'
      },
      /**
       * @event Player#thirdquartile
       */ {
        prop: 'thirdQuartileReported',
        percentage: 0.75,
        event: 'thirdquartile'
      }
    ];
    const currentItem = this.getCurrentPlaylistItem();

    for (let i = 0; i < timeEvents.length; ++i) {
      const timeEvent = timeEvents[i];
      if (
        !currentItem[timeEvent.prop] &&
        currentItem.hasBegunPlay &&
        player.currentTime() / player.duration() > timeEvent.percentage
      ) {
        this.emit(timeEvent.event);
        this.logEvent(timeEvent.event);
        currentItem[timeEvent.prop] = true;
        return;
      }
    }
  }

  /**
   * Just a helper to log events.
   *
   * @private
   * @param eventName
   */
  logEvent(eventName) {
    this.log('[EVENT]', eventName);
  }

  /**
   * Bind non-internal player specific events.
   *
   * @private
   */
  bindOtherEvents() {
    this.on('beforePlay', event => {
      this.logEvent('beforePlay');
      this.internalPlayerBeforePlay(event);
    });
    this.on('playlistItem', () => {
      this.logEvent('playlistItem');
    });
    this.on('firstFrame', () => {
      this.logEvent('firstFrame');
    });
    this.on('durationchange', () => {
      this.logEvent('durationchange');
      const tech = this.currentPlayer.tech();
      tech.trigger('durationchange');
    });
    this.on('dai_ad_break_started', event => {
      this.logEvent('adStart');
      this.emit('adStart', event);
    });
    this.on('dai_ad_break_ended', event => {
      this.logEvent('adEnded');
      this.emit('adEnded', event);
    });
    this.on('dai_ad_error', event => {
      this.logEvent('adError');
      this.emit('adError', event);
    });
    this.on('dai_ad_clicked', event => {
      this.logEvent('adClick');
      this.emit('adClick', event);
    });
    this.on('dai_ad_loaded', event => {
      this.logEvent('adLoaded');
      this.emit('adLoaded', event);
    });
    this.on('dai_ad_first_quartile', event => {
      this.logEvent('adFirstQuartile');
      this.emit('adFirstQuartile', event);
    });
    this.on('dai_ad_midpoint', event => {
      this.logEvent('adMidpoint');
      this.emit('adMidpoint', event);
    });
    this.on('dai_ad_third_quartile', event => {
      this.logEvent('adThirdQuartile');
      this.emit('adThirdQuartile', event);
    });
    this.on('playbackstalled', () => {
      this.logEvent('playbackstalled');
    });
  }

  internalPlayerBeforePlay() {}

  /**
   * The event callback for when the internal player is
   * ready.
   * @private
   * @fires Player#ready
   * @param event
   */
  internalPlayerReady() {
    /**
     * Player ready event.  Fired when both the Player
     * and Internal Player are ready.
     *
     * @event Player#ready
     */
    this.emit('ready');
    this.createControlSpacer();
    this.bindIndividualSourceEvents();

    // Remove olf captions.
    this.removeAllTextTracks();

    // Add new captions
    this.addCaptions(this.getVideoCaptions(this.getCurrentPlaylistItem()));

    // DVR
    this.initDvr();
  }

  /**
   * Initializes DVR for live videos.
   *
   * @private
   * @returns {Promise<void>}
   */
  async initDvr() {
    this.currentPlayer.on('loadedmetadata', () => {
      if (!this.isLiveVideo()) {
        return;
      }
      this.log('[DVR]', 'Initializing Live video');
      this.setupLiveVideo();
    });
  }

  /**
   * Determines whether current asset is live or not.
   *
   * @returns {boolean}
   */
  isLiveVideo() {
    const currentItem = this.getCurrentPlaylistItem();
    return currentItem.isLive || this.currentPlayer.duration() === Infinity;
  }

  /**
   * Setups up player for live playback.
   *
   * @private
   */
  setupLiveVideo() {
    const currentItem = this.getCurrentPlaylistItem();
    this.currentPlayer.addClass('is-live-asset');

    const LiveDisplay = this.currentPlayer.controlBar.liveDisplay.el();
    LiveDisplay.addEventListener('click', () => {
      this.currentPlayer.currentTime(this.currentPlayer.seekable().end(0));
      if (this.paused()) {
        this.play();
      }
    });

    currentItem.isLive = true;
  }

  /**
   * These need to be fired once per source, so
   * right after the player changes sources, this
   * must be called.
   * @private
   */
  bindIndividualSourceEvents() {
    this.currentPlayer.one('playing', event => {
      this.emit('firstFrame', event);
      this.emit('playlistItem', event);
    });
    this.detectQualityChangesForCurrentSource();
  }

  /**
   * The event callback for when an internal video begins
   * play.
   * @param {object} event
   * @private
   * @fires Player#play
   */
  internalPlayerPlay(event) {
    /**
     * Player play event.  Fired when any video playing
     * in the current player plays for the first time.
     * @event Player#play
     */
    this.emit('play', event);
  }

  /**
   * The event callback for when an internal video has
   * changed state to playing.
   *
   * @param {object} event
   * @private
   * @fires Player#playing
   */
  internalPlayerPlaying(event) {
    /**
     * Player playing event.  Fired when any video within
     * the current player changes from another state to
     * playing.
     * @event Player#playing
     */
    this.emit('playing', event);

    // Set hasBegunPlay to accurately track quartiles.
    const currentItem = this.getCurrentPlaylistItem();
    currentItem.hasBegunPlay = true;
    this.currentPlayer.removeClass('vjs-autoplaying');
  }

  /**
   * The event callback for when an internal video has
   * ended.
   *
   * @param {object} event
   * @private
   * @fires Player#ended
   */
  internalPlayerEnded(event) {
    /**
     * Player ended event.  Fired when any video playing
     * in the current player has ended.
     *
     * @event Player#ended
     */
    this.emit('ended', event);
    const endedCallback = this.getVideoEndedCallback();
    if (endedCallback) {
      endedCallback();
    } else {
      this.nextPlaylistItem();
    }
  }

  getVideoEndedCallback() {
    return this.videoEndedCallback || null;
  }

  /**
   * Sets a function or null as video ended
   *  callback.  Used primarily by end card plugin,
   *  but could be used elsewhere.
   *
   * @param cb
   */
  setVideoEndedCallback(cb) {
    if (typeof cb === 'function') {
      this.videoEndedCallback = cb;
    } else {
      delete this.videoEndedCallback;
    }
  }

  /**
   * Plays the next video in the playlist if one exists,
   * otherwise resets playhead to the first video in the
   * playlist, unless the loop config is not set or set
   * to false.
   */
  nextPlaylistItem() {
    if (this.playlistIndex < this.config.playlist.length - 1) {
      // We have more videos to play.
      ++this.playlistIndex;
    } else {
      // This was the last video in the playlist.
      if (!this.config.loopPlaylist) {
        return;
      }
      this.playlistIndex = 0;
    }
    this.emit('ended');
    this.currentPlayer.pause();
    this.queueNextVideo();
  }

  /**
   * Sets the current playlist playhead to an arbitrary index
   * in the current set of videos.
   * @param {integer} index - index of the video in the playlist to
   *  set the playhead at.
   */
  setPlaylistItem(index) {
    if (this.playlistIndex === index) {
      return;
    }
    if (index >= this.config.playlist.length || index < 0) {
      return;
    }
    this.emit('ended');
    this.currentPlayer.pause();
    this.playlistIndex = index;
    this.queueNextVideo();
  }

  /**
   * Plays the previous video in the playlist if one exists,
   * otherwise resets playhead to the last video in the
   * playlist, unless the loop config is not set or set
   * to false.
   */
  prevPlaylistItem() {
    if (this.playlistIndex > 0) {
      // We have more videos to play.
      --this.playlistIndex;
    } else {
      // This was the last video in the playlist.
      if (!this.config.loopPlaylist) {
        return;
      }
      this.playlistIndex = this.config.playlist.length - 1;
    }
    this.emit('ended');
    this.currentPlayer.pause();
    this.queueNextVideo();
  }

  /**
   * Returns whether or not ads are enabled and available.
   *
   * @returns {Player.ima|{cmsId, debug}|*|ima|{debug}|ImaPlugin}
   */
  adsAvailable() {
    // return window.google && window.google.ima;
    return (
      this.currentPlayer.ima &&
      this.currentPlayer.ads &&
      global.google &&
      global.google.ima
    );
  }

  /**
   * Returns the current type of ad.  Either `DAI`, `IMA`,
   * or `null`
   * @returns {string|null}
   */
  adType() {
    return this.config.adType || null;
  }

  /**
   * Builds and returns an object formatted for the share
   *  overlay plugin.
   */
  getShareConfig() {
    const config = {};
    const currentItem = this.getCurrentPlaylistItem();
    if (currentItem.url) {
      config.url = currentItem.url;
    }
    if (currentItem.poster) {
      config.image = currentItem.poster;
    }
    if (currentItem.title) {
      config.title = currentItem.title;
    }
    if (currentItem.description) {
      config.description = currentItem.description;
    }
    if (currentItem.share && currentItem.share.platforms) {
      config.platforms = currentItem.share.platforms;
    }
    if (currentItem.share && currentItem.share.emailBody) {
      config.emailBody = currentItem.share.emailBody;
    }
    if (currentItem.share && currentItem.share.via) {
      config.via = currentItem.share.via;
    }
    return config;
  }

  /**
   * Get captions currently attached to player.
   *
   * @returns {Array}
   */
  getCaptions() {
    return this.getRemoteTextTracks();
  }

  /**
   * Gets the captions currently attached to the internal player.
   *
   * @private
   * @returns {Array}
   */
  getRemoteTextTracks() {
    return this.currentPlayer ? this.currentPlayer.textTracks() : [];
  }

  /**
   * Removes a specific caption track from the player.
   * @param textTrack
   */
  removeTextTrack(textTrack) {
    if (!this.currentPlayer) {
      return;
    }
    this.currentPlayer.removeRemoteTextTrack(textTrack);
  }

  /**
   * Removes all captions from the player.
   */
  removeAllTextTracks() {
    if (!this.currentPlayer) {
      return;
    }
    const tracks = this.getRemoteTextTracks() || [];
    let trackCount = tracks.length;
    while (--trackCount >= 0) {
      this.removeTextTrack(tracks[trackCount]);
    }
  }

  /**
   * Gets captions from an item in the playlist.
   *
   * @param currentItem
   * @returns {Array}
   */
  getVideoCaptions(currentItem) {
    return currentItem.tracks
      ? currentItem.tracks.filter(track => track.kind === 'captions')
      : [];
  }

  /**
   * Add captions to the player.
   *
   * @param captions
   */
  addCaptions(captions) {
    if (!this.currentPlayer) {
      return;
    }
    for (let i = 0; i < captions.length; ++i) {
      this.currentPlayer.addRemoteTextTrack(
        {
          src: captions[i].src,
          language: captions[i].language,
          label: captions[i].language,
          kind: captions[i].kind
        },
        true
      );
    }
  }

  /**
   * Prepares next video for play.
   *
   * @private
   * @event Player#playlistItem - Fired when playlist changes
   *  video sources.
   * @event Player#beforePlay - Fired before playback occurs.
   */
  async queueNextVideo() {
    // If DAI, and DAI playing, reset it.
    if (this.linearAdPlaying && typeof DaiAds !== 'undefined') {
      DaiAds.resetDai(this);
    }

    const nextVideo = this.getPreparedPlaylistItem(
      this.config.playlist[this.playlistIndex]
    );
    if (!nextVideo) {
      return;
    }

    // Remove olf captions.
    this.removeAllTextTracks();

    // Add new captions
    this.addCaptions(this.getVideoCaptions(nextVideo));

    const poster = nextVideo.poster;

    // Set meta info
    this.setMeta({
      title: nextVideo.title,
      description: nextVideo.description
    });

    // Set thumbs if present.
    if (
      nextVideo.vttThumbnails &&
      typeof this.currentPlayer.vttThumbnails === 'object'
    ) {
      this.currentPlayer.vttThumbnails.src(nextVideo.vttThumbnails.src);
    } else if (
      nextVideo.vttThumbnails &&
      typeof this.currentPlayer.vttThumbnails === 'function'
    ) {
      this.currentPlayer.vttThumbnails(nextVideo.vttThumbnails.src);
    } else if (
      typeof this.currentPlayer.vttThumbnails === 'object' &&
      !nextVideo.vttThumbnails
    ) {
      this.currentPlayer.vttThumbnails.detach();
    }

    // Set share params
    if (
      typeof this.currentPlayer.socialShareOverlay === 'object' &&
      this.getShareConfig().platforms
    ) {
      this.currentPlayer.socialShareOverlay.set(this.getShareConfig());
    }

    // Set poster.
    this.currentPlayer.poster(poster);

    this.bindIndividualSourceEvents();
    this.emit('beforePlay');

    // If DAI, change source.
    if (
      this.adType() === 'dai' &&
      nextVideo.contentId &&
      DaiAds.isDaiAvailable() &&
      !nextVideo.ads_disabled
    ) {
      await DaiAds.setStitchedStream(
        this,
        nextVideo,
        this.config.ima.cmsId,
        (Array.isArray(nextVideo.sources)
          ? nextVideo.sources[0].src
          : nextVideo.sources.src) || null
      ).catch(fallback => {
        if (fallback) {
          this.src({
            src: fallback,
            type: TYPE_HLS
          });
        }
      });
    } else {
      this.setSource(nextVideo);
    }

    if (this.currentPlayer.paused()) {
      this.currentPlayer.play();
    }
  }

  /**
   * Adds items to the current playlist.
   *
   * @param {Array} items - items to add to playlist.
   * @param {integer} [index] - an index to add the playlist items.
   * @emits Player#playlistChanged
   */
  addPlaylistItems(items, index) {
    let playlist = this.config.playlist;
    if (!items || !items.length) {
      return;
    }
    if (typeof index !== 'undefined') {
      let slicedPlaylistBegin = playlist.slice(0, index) || [];
      let slicedPlaylistEnd = playlist.slice(index) || [];
      this.config.playlist = slicedPlaylistBegin.concat(
        items,
        slicedPlaylistEnd
      );
    } else {
      this.config.playlist = playlist.concat(items);
    }
    /**
     * @event Player#playlistChanged
     */
    this.emit('playlistChanged');
  }

  /**
   * Changes the contents of the playlist using Array.prototype.splice.
   *
   * @param {integer} start - Index at which to start changing the array
   *  (with origin 0)
   * @param {integer} deleteCount - An integer indicating the number
   *  of old array elements to remove.
   * @param {*} ...items - The elements to add to the array,
   *  beginning at the start index. If you don't specify any
   *  elements, splice() will only remove elements from the array.
   *  @emits Player#playlistChanged
   */
  splicePlaylist() {
    this.config.playlist = this.config.playlist.splice.call(
      this.config.playlist,
      ...arguments
    );

    /**
     * @event Player#playlistChanged
     */
    this.emit('playlistChanged');
  }

  /**
   * Gets a child index of a node in relation to it's parent.
   *
   * @param node
   * @returns {number}
   */
  getChildNumber(node) {
    return Array.prototype.indexOf.call(node.parentNode.childNodes, node);
  }

  /**
   * Creates a faux spacer so that progress bar
   *  can lie above controls without breaking layout
   *
   * @private
   */
  /* istanbul ignore next */
  createControlSpacer() {
    const player = this.currentPlayer;
    const videoJsButtonClass = videojs.getComponent('CustomControlSpacer');
    const concreteButtonClass = videojs.extend(videoJsButtonClass, {
      constructor: function() {
        videoJsButtonClass.call(this, player, { title: 'Spacer' });
      }
    });

    const progressSpacer = new concreteButtonClass();
    const placementIndex = this.getChildNumber(
      player.controlBar.progressControl.el()
    );
    const concreteButtonInstance = player.controlBar.addChild(
      progressSpacer,
      { componentClass: 'qualitySelector' },
      placementIndex
    );
    concreteButtonInstance.addClass('vjs-faux-progress-control');
  }

  /**
   * Creates the meta overlay component for the player.
   *
   * @private
   */
  /* istanbul ignore next */
  createMetaOverlay() {
    const player = this.currentPlayer;
    const videoJsComponent = videojs.getComponent('Component');
    const videoJsMetaClass = videojs.extend(videoJsComponent, {
      setText: function(text) {
        this.el().innerText = text ? this.decodeHtml(text) : '';
      },
      decodeHtml(html) {
        if (!this._htmlDecoder) {
          this._htmlDecoder = document.createElement('textarea');
        }
        this._htmlDecoder.innerHTML = html;
        return this._htmlDecoder.value;
      }
    });
    this.metaComponent = new videoJsMetaClass();
    this.titleComponent = new videoJsMetaClass();
    this.descriptionComponent = new videoJsMetaClass();

    this.metaComponent.addClass('vjs-meta-overlay');
    this.titleComponent.addClass('vjs-meta-title');
    this.descriptionComponent.addClass('vjs-meta-description');

    this.metaComponent.addChild(this.titleComponent);
    this.metaComponent.addChild(this.descriptionComponent);

    const placementIndex = this.getChildNumber(
      player.controlBar.progressControl.el()
    );
    player.addChild(this.metaComponent, {}, placementIndex);
  }

  /**
   * Sets meta descriptions for the player.
   *
   * @param {Object} meta - Object which defines key-value meta
   *  information for the player.
   */
  setMeta(meta) {
    let metaEmpty = true;
    if (this.titleComponent && meta.title) {
      this.titleComponent.setText(meta.title);
      metaEmpty = false;
    }
    if (this.descriptionComponent && meta.description) {
      this.descriptionComponent.setText(meta.description);
      metaEmpty = false;
    }
    this.currentPlayer[metaEmpty ? 'addClass' : 'removeClass'](
      'vjs-meta-empty'
    );
  }

  /**
   * Getter/setter for player source.
   * @param {Object} [item] - Item to set as source.
   */
  src(item) {
    if (!item) {
      return this.getSrc();
    }
    return this.currentPlayer.src(item);
  }

  /**
   * Returns the current player source.
   *
   * @returns {*}
   * @private
   */
  getSrc() {
    // If using HLS, source will be local blob on some browsers,
    // so we will have to use the hardcorded source.  This can be difficult
    // to predict if there are multiple sources.
    const hls = this.currentPlayer.tech({ IWillNotUseThisInPlugins: true }).hls;
    if (!hls) {
      return this.currentPlayer.src();
    }

    const sources = this.getCurrentPlaylistItem().sources;
    let hlsSource = sources.filter(src => (src.type = TYPE_HLS)).shift();
    // If hlsSouce, return it, otherwise return the current source.
    return hlsSource ? hlsSource.src : this.currentPlayer.src();
  }

  /**
   * Returns the current playlist item.
   * @param prepared
   * @returns {Object}
   */
  getCurrentPlaylistItem(prepared) {
    const item = this.config.playlist[this.playlistIndex];
    return prepared ? this.getPreparedPlaylistItem(item) : item;
  }

  /**
   * Callback to listen for quality changes on HLS playlist.
   *
   * @private
   * @emits Player#qualityChanged
   */
  /* istanbul ignore next */
  detectQualityChangesForCurrentSource() {
    const player = this.currentPlayer;
    let tracks = player.textTracks();
    let segmentMetadataTrack;

    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].label === 'segment-metadata') {
        segmentMetadataTrack = tracks[i];
      }
    }

    let previousPlaylist;

    if (segmentMetadataTrack) {
      segmentMetadataTrack.addEventListener('cuechange', () => {
        let activeCue = segmentMetadataTrack.activeCues[0];

        if (activeCue) {
          if (previousPlaylist !== activeCue.value.playlist) {
            /**
             * @event Player#qualitychanged
             */
            this.emit('qualitychanged', this.getQualityLevel());
          }
          previousPlaylist = activeCue.value.playlist;
        }
      });
    }
  }

  /**
   * Returns internal player element.
   * @returns {HTMLElement}
   */
  getPlayerElement() {
    const player = document.getElementById(this.playerId);
    if (player && player.tagName.toLowerCase() === 'video') {
      return player.parentNode;
    }
    return player;
  }

  /**
   * Get a playlist item at a specific index.
   *
   * @param index
   * @returns {null}
   */
  getPlaylistItem(index) {
    if (typeof index === 'undefined') {
      return null;
    }
    return this.config.playlist[index];
  }

  /**
   * Return the current players playlist.
   *
   * @returns {array} - Array of playlist items.
   */
  getPlaylist() {
    return this.config.playlist || [];
  }

  /**
   * Play current video/player instance.
   */
  play() {
    if (this.currentPlayer) {
      return this.currentPlayer.play();
    }
  }

  /**
   * Pauses current video/player instance.
   */
  pause() {
    if (this.currentPlayer) {
      this.currentPlayer.pause();
    }
  }

  /**
   * Returns paused state from current video/player instance.
   */
  paused() {
    if (this.currentPlayer) {
      return this.currentPlayer.paused();
    }
  }

  /**
   * Append and item to the end of the current playlist.
   * @param item
   * @emits Player#playlistChanged
   */
  appendPlaylistItem(item) {
    if (typeof item === 'undefined') {
      return;
    }
    this.config.playlist.push(item);

    /**
     * @event Player#playlistChanged
     */
    this.emit('playlistChanged');
  }

  /**
   * If player is in debug mode, will log
   * messages to the console.
   */
  log() {
    if (this.config && this.config.debug) {
      // eslint-disable-next-line no-console
      console.log.apply(console, arguments);
    }
  }

  /**
   * If player is in debug mode, will log
   * messages to the console.
   */
  trace() {
    if (this.config && this.config.debug) {
      // eslint-disable-next-line no-console
      console.trace.apply(console, arguments);
    }
  }

  /**
   * Destroy the player instance.
   */
  dispose() {
    // Bind visibility listener.
    const visProps = this.getVisibilityChangeVendorSpecific();
    document.removeEventListener(
      visProps.visibilityChange,
      e => this.handleVisibilityChange(e),
      false
    );

    // Clear stall interval in the case that it was started.
    clearInterval(this.timeupdateInterval);

    // Dispose the player.
    this.currentPlayer.dispose();
  }

  /**
   * Returns player fullscreen state
   *
   * @returns {boolean}
   */
  getFullscreen() {
    return this.currentPlayer ? this.currentPlayer.isFullscreen() : false;
  }

  /**
   * Returns current player config.
   *
   * @returns {Object} - Player config object.
   */
  getConfig() {
    return this.config || {};
  }

  /**
   * Get current playhead position in seconds.
   *
   * @returns {number} - Seconds from start of video.
   */
  getCurrentTime() {
    return this.currentPlayer ? this.currentPlayer.currentTime() : 0;
  }

  /**
   * Set current playhead position in seconds.
   *
   * @returns {number} - Seconds from start of video.
   */
  setCurrentTime(time) {
    return this.currentPlayer ? this.currentPlayer.currentTime(time) : null;
  }

  /**
   * Gets duration of current video.
   *
   * @returns {number} - Duration in seconds of current video.
   */
  getDuration() {
    return this.currentPlayer ? this.currentPlayer.duration() : 0;
  }

  /**
   * Get the version of this package.
   * @returns {*}
   */
  static getVersion() {
    return VERSION;
  }

  /**
   * Get the internal player version.
   * @returns {string}
   */
  static getInternalVersion() {
    return videojs.VERSION;
  }

  /**
   * Set path to resources for use as a library.
   *
   * @static
   * @param path
   */
  static setPublicPath(path) {
    __webpack_public_path__ = path || '';
  }

  /**
   * Returns the last player error.
   *
   * @returns {*|null}
   */
  getError() {
    return this.currentPlayer.error() || null;
  }

  /**
   * Determine whether the social share overlay
   *  plugin is open.
   *
   * @returns {boolean|*}
   */
  shareOverlayOpen() {
    return this.currentPlayer.hasClass('vjs-social-share-overlay-open');
  }

  /**
   * If share overlay plugin is available, show the
   *  overlay.
   */
  showShareOverlay() {
    const shareOverlay = this.currentPlayer.socialShareOverlay;
    if (typeof shareOverlay === 'object' && !this.isLinearAdPlaying()) {
      shareOverlay.showShareOverlay();
    }
  }

  /**
   * If share overlay plugin is available, hide the
   *  overlay.
   */
  hideShareOverlay() {
    const shareOverlay = this.currentPlayer.socialShareOverlay;
    if (typeof shareOverlay === 'object') {
      shareOverlay.hideShareOverlay();
    }
  }

  /**
   * Return videojs browser object.
   *
   * @returns {Object}
   */
  getBrowsers() {
    return videojs.browser;
  }

  /**
   * Helper for getting vendor specific visibility
   *  change API events/status.
   *
   * @private
   * @returns {{hidden: *, visibilityChange: *}}
   */
  getVisibilityChangeVendorSpecific() {
    let hidden, visibilityChange;

    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    return {
      hidden: hidden,
      visibilityChange: visibilityChange
    };
  }

  /**
   * Player visibility change listener.
   *
   * @private
   */
  handleVisibilityChange() {
    const visProps = this.getVisibilityChangeVendorSpecific();
    if (document[visProps.hidden]) {
      this.beforePlayerHiddenState = this.paused() ? 'paused' : 'playing';
      this.pause();
    } else if (this.beforePlayerHiddenState === 'playing') {
      this.play();
    }
  }

  /**
   * Loads a language file and queue's it for
   *  videojs instantiation.
   *
   * @param langCode
   * @returns {Promise<void>}
   *
   * @example
   * // Should be set before player instantiation, and
   * // will apply to all players on the page.
   * Player.setLanguage('es')
   * ...
   * new Player(...)
   */
  static async setLanguage(langCode) {
    const LanguageImport = await import(/* webpackChunkName: 'Language' */ './Plugins/Language');
    const Language = LanguageImport.default;
    if (typeof Language[langCode] !== 'function') {
      throw Error(`Could not load language ${langCode}`);
    }
    window._player_lang_ = langCode;
    await Language[langCode]();
  }

  /**
   * Build a fully-qualified path to a autoplay testable
   *  video file.
   *
   * @static
   * @returns {string} Fully qualified path to empty video.
   */
  static emptyVideoSource() {
    return (__webpack_public_path__ || '') + emptyMp4WithSound;
  }

  /**
   * Detects browser autoplay support.  Fallsback to false
   *  for older browsers despite the fact that they
   *  may support it.
   *
   * @static
   * @returns {Promise<boolean>}
   */
  static browserCanAutoPlay() {
    return new Promise(resolve => {
      document.addEventListener(
        'DOMContentLoaded',
        () => {
          const video = document.createElement('video');
          video.style.opacity = 0;
          video.style.width = 0;
          video.style.height = 0;
          video.src = Player.emptyVideoSource();
          document.body.appendChild(video);

          // Try/catch since play does not always return a promise.
          try {
            video
              .play()
              .then(() => resolve(true))
              .catch(() => resolve(false))
              .finally(() => {
                document.body.removeChild(video);
              });
          } catch (e) {
            resolve(false);
          }
        },
        false
      );
    });
  }

  /**
   * Sets autoplay property on Player object
   *  and also returns that value in a promise.
   *
   * @static
   * @async
   * @returns {Promise<boolean>}
   */
  static async setAutoPlayProperty() {
    global.__canAutoPlay = await Player.browserCanAutoPlay();
  }
}

export default Player;
