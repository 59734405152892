import videojs from 'video.js';

/**
 * Rewind Button Plugin
 */
class Rewind {
  /**
   *
   * @param {Player} player - The player instance.
   * @param {number} rewindTime - Time in seconds to rewind player.
   */
  constructor(player, rewindTime = 15) {
    this.currentPlayer = player.currentPlayer;
    this.rewindTime = rewindTime;
    this.createButton();
  }

  /* istanbul ignore next */
  createButton() {
    const videoJsButton = videojs.getComponent('Button');
    const currentPlayer = this.currentPlayer;
    const videoJsButtonClass = new videoJsButton(this.currentPlayer, {
      title: currentPlayer.localize('Rewind ' + this.rewindTime + ' seconds')
    });
    videoJsButtonClass.on('click', () => this.doRewind());
    const placementIndex =
      0 +
      this.currentPlayer.controlBar
        .children()
        .indexOf(this.currentPlayer.controlBar.volumePanel);
    const concreteButtonInstance = this.currentPlayer.controlBar.addChild(
      videoJsButtonClass,
      { componentClass: 'socialShare' },
      placementIndex
    );
    concreteButtonInstance.addClass('vjs-rewind-button');
  }

  doRewind() {
    const newTime = Math.max(
      0,
      this.currentPlayer.currentTime() - this.rewindTime
    );
    this.currentPlayer.currentTime(newTime);
  }
}

export default Rewind;
