import xhr from 'xhr';
import videojs from 'video.js';

/**
 * Doubleclick As Insertion Player functionality.
 * @class
 */
class DaiAds {
  /**
   * Get entitlements from VOD Server.
   *
   * @param {Number} videoId
   * @returns {Promise<*>}
   */
  static getEntitlement(videoId) {
    const endpoint = 'https://vod.wwe.com/v1/entitlement/';
    return new Promise(resolve => {
      xhr(
        {
          uri: endpoint + videoId
        },
        (err, res, body) => {
          if (err) {
            return resolve({});
          }
          if (res.statusCode === 200) {
            return resolve(JSON.parse(body));
          }
          return resolve(JSON.parse(body));
        }
      );
    });
  }

  static isDaiAvailable() {
    return window.google && google.ima && google.ima.dai;
  }

  /**
   * Request a VOD asset URL with a stitched ad.
   *
   * @static
   * @param {object} config - The object describing the media
   *  source, content ID, cms ID, click element, and current
   *  player.
   * @returns {Promise}
   */
  static requestVODStream(config) {
    let {
      player,
      cmsId,
      contentId,
      contentUrl,
      clickElement,
      adTagParameters
    } = config;

    if (!DaiAds.isDaiAvailable()) {
      throw 'DAI is not defined.';
    }

    if (!clickElement) {
      clickElement = DaiAds.createClickElement(player.currentPlayer.el());
    }

    let playerEl = player.currentPlayer.el();
    let videoElement = playerEl.querySelector('video');
    let streamRequest = DaiAds.createStreamRequest(
      cmsId,
      contentId,
      adTagParameters
    );

    if (!player.streamManager) {
      player.streamManager = DaiAds.createStreamManager(
        videoElement,
        clickElement
      );
    } else {
      DaiAds.removeAllStreamManagerEventListener(player);
      player.streamManager.reset();
    }

    if (!playerEl.querySelector('.vjs-dai-information')) {
      DaiAds.createDaiInfoComponent(player.currentPlayer);
    }

    /* istanbul ignore next */
    return new Promise((resolve, reject) => {
      if (!player.streamManager) {
        return reject('No stream manager');
      }

      // Setup ad duration helpers
      let adDuration = 0;
      const daiDurationUpdate = () =>
        DaiAds.updateDaiTimeRemaining.call(
          playerEl,
          player.currentPlayer.currentTime(),
          adDuration
        );
      let adStartedCallback;

      adStartedCallback = event => {
        player.emit('dai_ad_break_started', event);
        player.linearAdPlaying = true;
        player.currentPlayer.addClass('vjs-dai-ad-playing');
        player.currentPlayer.on('timeupdate', daiDurationUpdate);
        player.currentPlayer.on('timeupdate', DaiAds.preventSeek.bind(player));
        DaiAds.removeStreamManagerEventListener(
          player,
          google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
          adStartedCallback
        );
      };

      // Ad break started.
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
        adStartedCallback,
        false
      );

      // Ad break ended.
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED,
        event => {
          const currentItem = player.getCurrentPlaylistItem();
          const currentCaptions = player.getCaptions() || [];
          const daiCaptions = currentItem._daiSubtitles || [];

          // Check to determine if embedded captions were supported.
          if (daiCaptions.length && currentCaptions.length === 0) {
            // If embedded captions arent supported, but DAI sent
            // back subtitles, we need to manually add them to
            // videojs
            player.addCaptions([
              {
                language: daiCaptions[0].language,
                kind: 'captions',
                src: daiCaptions[0].webvtt
              }
            ]);
          }

          // Reset player state to reflect ad ended/content playing
          player.currentPlayer.removeClass('vjs-dai-ad-playing');
          player.linearAdPlaying = false;
          player.emit('dai_ad_break_ended', event);
          player.emit('durationchange');
          player.currentPlayer.off('timeupdate', daiDurationUpdate);
          player.currentPlayer.off('timeupdate', DaiAds.preventSeek);
        },
        false
      );

      // Ad loaded.
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.LOADED,
        event => {
          player.emit('dai_ad_loaded', event);
          const streamData = event.getStreamData();
          const currentItem = player.getCurrentPlaylistItem();

          // Remove all captions since captions are embedded in stream,
          // and if they're not, they'd be incorrect anyway.
          currentItem._daiSubtitles = streamData.subtitles;
          player.removeAllTextTracks();

          resolve(streamData.url);
        },
        false
      );

      // Ad clicked.
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.CLICK,
        event => {
          player.pause();
          player.emit('dai_ad_clicked', event);
        },
        false
      );

      // Ad started.
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.STARTED,
        event => {
          adDuration = event.getAd().getDuration();
          const currentItem = player.getCurrentPlaylistItem();
          currentItem.adLength = adDuration;
          currentItem.steamHasStitchedAd = true;
          player.emit('durationchange');
          player.emit('dai_ad_started', event);
          player.currentPlayer.removeClass(
            'vjs-waiting',
            'vjs-dai-ad-requested'
          );
        },
        false
      );

      // Error occurred, load backup URL.
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.ERROR,
        event => {
          player.log('DAI Error', event.getStreamData());
          player.emit('dai_ad_error', event.getStreamData());
          player.currentPlayer.removeClass(
            'vjs-waiting',
            'vjs-dai-ad-requested'
          );
          resolve(contentUrl);
        },
        false
      );

      // Bind ad break started event.
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
        adStartedCallback,
        false
      );

      // DAI progress events.
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.FIRST_QUARTILE,
        event => player.emit('dai_ad_first_quartile', event),
        false
      );
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.MIDPOINT,
        event => player.emit('dai_ad_midpoint', event),
        false
      );
      DaiAds.registerStreamManagerEventListener(
        player,
        google.ima.dai.api.StreamEvent.Type.THIRD_QUARTILE,
        event => player.emit('dai_ad_third_quartile', event),
        false
      );

      // VJS Loading spinner and play icon.
      player.currentPlayer.addClass('vjs-dai-ad-requested');

      // After all events are bound, request the ad.
      player.streamManager.requestStream(streamRequest);
    });
  }

  static registerStreamManagerEventListener(player, event, callback) {
    if (!player.streamManagerEvents) {
      player.streamManagerEvents = [];
    }

    player.streamManagerEvents.push({
      event: event,
      callback: callback
    });

    player.streamManager.addEventListener(event, callback);
  }

  static removeAllStreamManagerEventListener(player) {
    if (!player.streamManagerEvents) {
      return;
    }
    const events = [].concat(player.streamManagerEvents);
    for (let i = 0; i < events.length; ++i) {
      DaiAds.removeStreamManagerEventListener(
        player,
        events[i].event,
        events[i].callback
      );
    }
  }

  static removeStreamManagerEventListener(player, event, callback) {
    if (!player.streamManagerEvents) {
      return;
    }

    player.streamManagerEvents = player.streamManagerEvents.filter(
      _event => event !== _event.event
    );

    player.streamManager.removeEventListener(event, callback);
  }

  static resetDai(player) {
    DaiAds.removeAllStreamManagerEventListener(player);
    player.linearAdPlaying = false;
    player.currentPlayer.removeClass('vjs-dai-ad-playing');
    player.currentPlayer.removeClass('vjs-dai-ad-requested');
    player.streamManager.reset();
    delete player.streamManager;
  }

  static createClickElement(playerEl) {
    const id = 'daiClickElement';
    let el = playerEl.querySelector('#' + id);
    if (el) {
      return el;
    }
    el = document.createElement('div');
    el.id = 'daiClickElement';
    playerEl.appendChild(el);
    return el;
  }

  /**
   * Prevent seek during DAI ad play.
   * @this {Player}
   */
  static preventSeek() {
    const currentTime = this.currentPlayer.currentTime();
    const playerTime = Math.max(0, this.playerTime); // Player time should never be negative.
    const positionDiff = currentTime - (playerTime || 0);

    if (this.linearAdPlaying) {
      if (positionDiff > 1 || positionDiff < -1) {
        this.currentPlayer.currentTime(playerTime);
        return;
      }
    }
    this.playerTime = currentTime;
  }

  /**
   * Create a DAI stream manager instance.
   *
   * @static
   * @param {HTMLElement} videoElement
   * @param {HTMLElement} clickElement
   * @returns {google.ima.dai.api.StreamManager}
   * @see https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/apis
   */
  static createStreamManager(videoElement, clickElement) {
    let streamManager = new google.ima.dai.api.StreamManager(videoElement);
    streamManager.setClickElement(clickElement);
    return streamManager;
  }

  /**
   * Create a DAI stream request instance.
   *
   * @static
   * @param {integer} cmsId
   * @param {integer} videoId
   * @param {Object} adTagParamseters - The ad params to send to dfp.
   *  @see https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/apis#ima.dai.api.StreamRequest.adTagParameters
   * @returns {google.ima.dai.api.VODStreamRequest}
   * @see https://developers.google.com/interactive-media-ads/docs/sdks/html5/dai/apis
   */
  static createStreamRequest(cmsId, videoId, adTagParameters) {
    let streamRequest = new google.ima.dai.api.VODStreamRequest();
    streamRequest.contentSourceId = cmsId;
    streamRequest.videoId = videoId;
    streamRequest.adTagParameters = adTagParameters || {};
    return streamRequest;
  }

  /**
   * Updates element with 'time remaining' message.
   *
   * @param {Number} currentTime - The current playback time of the stream.
   * @param {Number} adDuration - The duration of the ad.
   */
  static updateDaiTimeRemaining(currentTime, adDuration) {
    const timeRemaining = Math.max(Math.floor(adDuration - currentTime), 0);
    const vjsTimeRemainingEl = this.querySelector('.vjs-dai-information-text');
    if (!vjsTimeRemainingEl) {
      return;
    }
    /* istanbul ignore next */
    vjsTimeRemainingEl.innerHTML = `This ad will end in <span class="vjs-dai-time-remaining">${timeRemaining}</span> seconds.`;
  }

  /**
   * Creates an empty component to house the 'time remaining'
   *  copy.
   * @param {Player} vjsPlayer - videojs Player object.
   * @private
   */

  /* istanbul ignore next */
  static createDaiInfoComponent(vjsPlayer) {
    const spacerEl = vjsPlayer.controlBar.durationDisplay.el();
    const spacerPosition = Array.prototype.indexOf.call(
      spacerEl.parentNode.childNodes,
      spacerEl
    );
    const videoJsComponentClass = videojs.getComponent('Component');
    const daiInfoClass = videojs.extend(videoJsComponentClass, {
      constructor: function() {
        videoJsComponentClass.call(this, vjsPlayer);
      }
    });

    const daiInfoComponent = new daiInfoClass();
    const textEl = document.createElement('span');

    textEl.innerHTML = 'Loading...';
    textEl.className = 'vjs-dai-information-text';
    daiInfoComponent.el().appendChild(textEl);

    vjsPlayer.controlBar
      .addChild(
        daiInfoComponent,
        { componentClass: 'qualitySelector' },
        spacerPosition + 1
      )
      .addClass('vjs-dai-information');
  }

  static isDaiSupported(player) {
    const browsers = player.getBrowsers();
    const ie_11_or_less = browsers.IE_VERSION && browsers.IE_VERSION <= 11;
    return !ie_11_or_less;
  }

  static async setStitchedStream(player, currentItem, cmsId, fallbackUrl) {
    // Default DAI Params.
    let adTagParameters = {
      pp: 'dfpdai',
      cust_params: 'dfpdai=true'
    };

    // Get player browser object.
    const browsers = player.getBrowsers();

    // Check to see if we should ask for lower rendition for mobile safari.
    if (browsers.IS_IPHONE && (browsers.IS_ANY_SAFARI || browsers.IS_SAFARI)) {
      adTagParameters['dai-os'] = 100;
    }

    // Does this platform support DAI?
    if (!DaiAds.isDaiSupported(player)) {
      player.log('[DAI]', 'This browser is not supported.');
      throw fallbackUrl;
    }

    // Build the ad tag parameters.
    if (currentItem.adTagParameters) {
      adTagParameters = Object.assign(
        {},
        adTagParameters,
        currentItem.adTagParameters
      );
    }

    // If the entitlements are not pre-set than we will need
    // to get them from the VOC server.
    if (!currentItem.entitlements) {
      currentItem.entitlements =
        (await DaiAds.getEntitlement(currentItem.contentId)) || {};
    }

    // If ads disabled, just play the asset.
    if (
      typeof currentItem.entitlements.ads_enabled !== 'undefined' &&
      !currentItem.entitlements.ads_enabled
    ) {
      throw fallbackUrl;
    }

    // If cms id is provided, use that instead.
    if (currentItem.entitlements.cms_id) {
      cmsId = currentItem.entitlements.cms_id;
    }

    // If Geoblocked, return no source.
    if (currentItem.entitlements.geoblock) {
      return null;
    }

    // If CDN, assign it in the request.
    adTagParameters['dai-dlid'] =
      currentItem.entitlements.cdn || 'default_delivery_26788';

    let streamUrl;
    try {
      streamUrl = await DaiAds.requestVODStream({
        player: player,
        contentId: currentItem.contentId,
        cmsId: cmsId,
        contentUrl: fallbackUrl,
        adTagParameters: adTagParameters
      });
      player.log('[DAI]', 'Using stitched stream.', streamUrl);
    } catch (e) {
      player.log('[DAI]', 'Using fallback URL.', e);
    }

    if (!streamUrl) {
      throw fallbackUrl;
    }

    player.src({
      src: streamUrl,
      type: 'application/x-mpegURL'
    });

    return streamUrl;
  }
}

export default DaiAds;
