/**
 * Class handles functionality of the end card plugin.
 * Should be instantiated immediately after player instantiation
 * or could miss the 'play' event of the initial video.
 */
class EndCard {
  /**
   *
   * @param {Player} player - The player instance to configure end cards for.
   */
  constructor(player) {
    this.player = player;

    // For each play, check if we have an end card.
    this.player.on('firstFrame', () => this.onVideoPlay());
  }

  /**
   * Return current video from internal player.
   * @returns {*|Object}
   */
  getCurrentVideo() {
    return this.player.getCurrentPlaylistItem();
  }

  /**
   * Video play event callback.
   */
  onVideoPlay() {
    const currentVideo = this.getCurrentVideo();

    if (currentVideo.endCard) {
      this.setEndCard(currentVideo.endCard);
      this.player.setVideoEndedCallback(() => this.displayEndCard());
    } else {
      this.player.setVideoEndedCallback();
    }
  }

  /**
   * Sets the contents of the current videos endcard.
   *
   * @param spec
   */
  setEndCard(spec) {
    this.currentEndCard = spec;

    if (!this.endCardEl) {
      this.endCardEl = this.createEndCard();
    }

    this.endCardEl.innerHTML = this.buildEndCard(spec.content);
    this.postProcessEndCard();
  }

  /**
   * Process end card content as a string before
   * inserting into the dom.
   *
   * @param content
   */
  buildEndCard(content) {
    return content.replace(
      /%countdown%/i,
      '<span class="endcard-countdown"></span>'
    );
  }

  /**
   * Handle post processing of end card content
   * after content has been passed into the DOM.
   */
  postProcessEndCard() {
    const proceedNextEls = this.endCardEl.querySelectorAll(
      '[endcard-proceed-next]'
    );
    const replayEls = this.endCardEl.querySelectorAll('[endcard-replay]');

    for (let i = 0; i < proceedNextEls.length; ++i) {
      proceedNextEls[i].addEventListener('click', event =>
        this.endCardComplete(event)
      );
    }

    for (let i = 0; i < replayEls.length; ++i) {
      replayEls[i].addEventListener('click', event =>
        this.requestReplay(event)
      );
    }
  }

  /**
   * Create end card container in player.
   *
   * @returns {Element}
   */
  createEndCard() {
    const playerEl = this.player.getPlayerElement();
    const el = document.createElement('div');
    el.id = 'player-endcard';
    playerEl.appendChild(el);
    return el;
  }

  /**
   * Show the end card in the player.
   */
  displayEndCard() {
    if (this.endCardVisible) {
      return;
    }
    this.endCardVisible = true;
    this.player.pause();
    this.player.currentPlayer.addClass('vjs-endcard-visible');
    const duration = this.currentEndCard.duration;
    if (duration !== 0) {
      const durationInSeconds = duration || 15;
      const durationInMilliSeconds = durationInSeconds * 1000;
      const now = new Date();
      this.endCardEndTime = now.setSeconds(
        now.getSeconds() + durationInSeconds
      );
      this.endCardInterval = setInterval(() => this.updateEndCard(), 500);
      this.endCardTimeout = setTimeout(
        () => this.endCardComplete(),
        durationInMilliSeconds
      );
      this.player.currentPlayer.addClass(
        'vjs-endcard-time-' + durationInSeconds
      );
      this.player.emit('endcardshow');
    }
  }

  /**
   * Update endcard current state while it's being
   * displayed.
   */
  updateEndCard() {
    const now = new Date();
    const remainingTime = Math.round((this.endCardEndTime - now) / 1000);
    if (!this.endCardEl) {
      return;
    }
    const countdownEls = this.endCardEl.querySelectorAll('.endcard-countdown');
    const playerEl = this.player.getPlayerElement();
    playerEl.className = playerEl.className.replace(
      /(vjs-endcard-time-[0-9]+)/gi,
      'vjs-endcard-time-' + remainingTime
    );
    for (let i = 0; i < countdownEls.length; ++i) {
      countdownEls[i].innerText = remainingTime;
    }
  }

  /**
   * Clear end car intervals and timeouts and
   * reset any other necessary elements after
   * end card is done being displayed.
   */
  clearEndCardTimers() {
    if (this.endCardInterval) {
      clearInterval(this.endCardInterval);
    }
    if (this.endCardTimeout) {
      clearTimeout(this.endCardTimeout);
    }
    const playerEl = this.player.getPlayerElement();
    playerEl.className = playerEl.className.replace(
      /(vjs-endcard-time-[0-9]+)/gi,
      ''
    );
    this.endCardVisible = false;
    this.player.emit('endcardhide');
  }

  /**
   * Callback for when an endcard should no longer be displayed.
   *
   * @param e
   */
  endCardComplete(e) {
    if (e) {
      e.preventDefault();
    }
    this.player.currentPlayer.removeClass('vjs-endcard-visible');
    this.clearEndCardTimers();
    this.player.nextPlaylistItem();
  }

  /**
   * Callback for when an end card requests a replay
   * of a current video.
   *
   * @param e
   */
  requestReplay(e) {
    if (e) {
      e.preventDefault();
    }
    this.player.currentPlayer.removeClass('vjs-endcard-visible');
    this.clearEndCardTimers();
    this.player.setPlaylistItem(this.player.playlistIndex);
    this.player.play();
  }
}

export default EndCard;
