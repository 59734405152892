/**
 * Class user to describe player default configuration.
 * @class
 */
class PlayerDefaults {
  /**
   * Returns an object describing the default
   * @returns {object}
   */
  constructor() {
    return {
      // Config will be extended into to ads/ima config.
      ima: {
        debug: false
      },
      techOrder: ['html5'],
      pauseInBackground: true
    };
  }
}

export default PlayerDefaults;
