/**
 * Player API.  This class is only extendable, and all
 * players which extend it should implement all the
 * described methods.
 *
 * @interface
 */
class Api {
  constructor() {
    if (new.target === Api) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }
  }

  /**
   * Returns playlist currently being used by
   * the player.
   */
  getPlaylist() {}

  /**
   * Get the current numerical index specifiying which
   * video in the playlist is currently being played.
   */
  getCurrentIndex() {}

  /**
   * Returns an object representing the video
   * currently being played.
   */
  getCurrentPlaylistItem() {}

  /**
   * Set internal playhead to play the next
   * playlist item.
   */
  nextPlaylistItem() {}

  prevPlaylistItem() {}

  setPlaylistItem() {}

  // appendPlaylistItem(item){}
  // addPlaylistItem(item, index){}
  addPlaylistItems() {}

  splicePlaylist() {}

  getConfig() {}

  getFullscreen() {}

  getCurrentTime() {}

  getDuration() {}
}

export default Api;
